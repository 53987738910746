.configurator__choice {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 20px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 350ms ease-in-out;
  object-fit: cover;
}

.configurator__choice:hover {
  background-color: #636c41;
}

.configurator__choice img {
  height: 70%;
}

.configurator__choice img.photo {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.configurator__choice .info-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  color: var(--primary-font-color--light);
}

.configurator__choice .info-icon.hovered {
  position: absolute;
  right: 5px;
  top: 5px;
  color: white;
}

.configurator__choice .choice-title {
  position: absolute;
  font-size: 1.3rem;
  font-weight: 600;
  display: none;
}

.configurator__choice .choice-title.hovered {
  color: white;
  display: block;
}

@media screen and (max-width: 768px) {
  .configurator__choice:hover {
    background-color: transparent;
  }
}