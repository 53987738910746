.cookie_policy__container {
  padding: 25px;
  padding-top: 15vh;
  min-height: 85vh;
  
  color: var(--primary-font-color);
}

.cookie_policy__container h2 {
  font-size: 1.2rem;
  padding: 10px 0;
}

.cookie_policy__container p, li {
  font-size: 0.9rem;
  padding: 5px 10px;
}

