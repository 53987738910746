.navbar {
    display: flex;
    height: 10vh;
    width: 100%;
    background-color: #ffffff;

    position: fixed;
    top: 0;
    z-index: 5;
}

.navbar .navbar__logo  {
    height: 100%;
    width: 100px;
    display: flex;
    align-items: center;
    margin-left: 40px;
}

.navbar .navbar__logo img {
    height: 70%;
    z-index: 50;
}

.navbar .navbar__menu {
    display: flex;
    align-items: center;
    height: 100%;
    color: black;
    margin-left: auto;
    margin-right: 10%;
}

.navbar .navbar__menu ul {
    display: flex;
    list-style: none;
    gap: 50px;
}

.navbar .navbar__menu ul li {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--primary-green);
}

.navbar .navbar__menu a {
    position: relative;
    text-decoration: none;
    color: var(--primary-green);
}

.navbar .navbar__menu a::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -2px;
    transform: translateX(-50%);
    width: 0%;
    height: 1px;
    background-color: var(--primary-green);
    transition: width 0.3s ease-out;
  }

.navbar .navbar__menu a:hover::before {
    width: 100%;
  }


  /*  MOBILE  */

.navbar__menu--mobile {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 100%;
    
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.navbar__menu--mobile .hamburger {
    margin-right: 10px;
}

.navbar__menu--mobile .filling {
    position: absolute;
    background-color: white;
    top: 10vh;
    left: 0;
    width: 100%;
    height: 90vh;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: none;
}

.navbar__menu--mobile .filling.active {
    opacity: 0.4;
}

.navbar__menu--mobile .menu__items {
    position: absolute;
    background-color: white;
    top: 9vh;
    right: 0;
    width: 0;
    height: 90vh;
    padding: 0;
    list-style: none;

    transition: width 0.5s ease-in-out;
}

.navbar__menu--mobile .menu__items.active {
    width: 50%;
}

.navbar__menu--mobile .menu__item {
    padding: 20px 0;
    text-align: center;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
}

.navbar__menu--mobile .menu__item.active {
    opacity: 1;
}

.navbar__menu--mobile .menu__item a {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--primary-green);
    text-decoration: none;
}
