.cookie_notification__container {
  background-color: white;
  height: 7.5vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  z-index: 100;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-size: 0.8rem;
}

.cookie_notification__container.hide {
  display: none;
}

.cookie_notification__container a {
  color: var(--primary-green)
}

.cookie_notification__container button {
  top: 0;
  padding: 7.5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: var(--primary-green);
  color: #fff;
  font-family: 'Playfair Display', serif;
  font-size: 0.8rem;
  cursor: pointer;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
}

@media screen and (max-width: 768px) {
  .cookie_notification__container {
    height: 12.5vh;
    font-size: 0.7rem;
  }
}