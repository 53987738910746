.about__text-container {
  display: grid;
  grid-template-areas: 
  "img text"
  ;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
  margin-bottom: 70px;
  width: 90%;
}

.about__text-container:nth-of-type(odd) {
  grid-template-areas: 
  "text img"
  ;
}

.about__image{
  grid-area: img;
  display: flex;
  width: 100%;
}

.about__text-container .about__text h2 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.about__text-container .about__text {
  grid-area: text;
  max-width: 700px;
  width: 100%;
}

.about__text-container > *:first-child {
  transition: all 1s ease-out;
  opacity: 0;
  transform: translateX(-25px);
}

.about__text-container > *:last-child {
  transition: all 1s ease-out;
  opacity: 0;
  transform: translateX(25px);
}

.about__text-container:nth-of-type(odd) > *:first-child {
  transition: all 1s ease-out;
  opacity: 0;
  transform: translateX(25px);
}

.about__text-container:nth-of-type(odd) > *:last-child {
  transition: all 1s ease-out;
  opacity: 0;
  transform: translateX(-25px);
}

.about__text-container--visible > *:first-child {
  opacity: 1;
  transform: translateX(0);
}

.about__text-container--visible > *:last-child {
  opacity: 1;
  transform: translateX(0);
}

.about__text-container--visible:nth-of-type(odd) > *:first-child {
  opacity: 1;
  transform: translateX(0);
}

.about__text-container--visible:nth-of-type(odd) > *:last-child {
  opacity: 1;
  transform: translateX(0);
}


@media screen and (max-width: 768px) {
  .about__text-container:nth-of-type(odd),
  .about__text-container {
    grid-template: auto / 1fr;
    grid-template-areas: 
    "img"
    "text"
    ;
    gap: 30px;
    margin: 0 20px 30px 20px;
  }
}