.contact_form {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.contact_form input {
    margin: 5px 0;
    padding: 12.5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: var(--primary-font-color);
    font-family: 'Playfair Display', serif;
    font-size: 1rem;
    height: 50px;
}

.contact_form input:focus {
    outline: none;
    border: 1px solid #a3a3a3;
}

.contact_form textarea {
    margin: 5px 0;
    padding: 12.5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    height: 100px;
    color: var(--primary-font-color);
    font-family: 'Playfair Display', serif;
    font-size: 1rem;
}

.contact_form textarea:focus {
    outline: none;
    border: 1px solid #a3a3a3;
}

.submit__wrapper {
    position: relative;
}

.contact_form .submit-button {
    position: absolute;
    top: 0;
    margin: 5px 0;
    padding: 12.5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: var(--primary-green);
    color: #fff;
    font-family: 'Playfair Display', serif;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
}

.contact_form .loading-spinner__wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: var(--primary-green);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 50px;
    margin: 5px 0;
    display: none;
}

.contact_form .message__wrapper {
    position: absolute;
    padding: 12.5px;
    text-align: center;
    top: 0;
    width: 100%;
    background-color: var(--primary-green);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 50px;
    margin: 5px 0;
    color: white;
    display: none;
}


@media screen and (max-width: 768px) {
    .contact_form {
        width: 90%;
    }

    .message__wrapper {
        font-size: 0.7rem;
    }

}