.product__container {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1.3;
  overflow: hidden;
}

.carousel {
  position: relative;
  width: 100%;
  height: 60%;
}

.carousel__track-container {
  height: 100%;
}

.carousel__track {
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  transition: transform 0.5s ease-in-out;
}

.carousel__slide {
  position: absolute;
  height: 100%;
  width: 100%;
}

.carousel__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.carousel__button svg {
  width: 100%;
  height: 100%;
}

.carousel__button--left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translatey(-50%);
  width: 12.5%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: none;
  color: white;
  background-color: var(--primary-green);  
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 50;
  margin-left: 10px;
  opacity: 0;
}

.carousel__button--right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translatey(-50%);
  width: 12.5%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: none;
  color: white;
  background-color: var(--primary-green);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 50;
  margin-right: 10px;
  opacity: 0;
}

.carousel__button--right,
.carousel__button--left {
  transition: all 0.3s ease-in-out;
}

.hidden {
  display: none;
}

.visible {
  opacity: 1;
}

.product__details__container h2 {
  margin: 5px 0;
}


.product__details__container p {
  margin-bottom: 7.55px;
}


